// components/MyBets.js
import React, { useState, useEffect, useCallback } from 'react';
import { firestore } from '../../services/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { calculatePoints } from '../../utils/scoring';
import '../../styles/MyBets.css';

const MyBets = () => {
    const [userBets, setUserBets] = useState([]);
    const [candidates, setCandidates] = useState({});
    const [results, setResults] = useState(null);
    const { currentUser } = useAuth();

    const fetchCandidates = useCallback(async () => {
        try {
            const candidatesCollection = collection(firestore, 'candidates');
            const candidatesSnapshot = await getDocs(candidatesCollection);
            const candidatesMap = {};
            candidatesSnapshot.docs.forEach(doc => {
                candidatesMap[doc.id] = doc.data();
            });
            setCandidates(candidatesMap);
            console.log('Candidates fetched:', candidatesMap);
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    }, []);

    const fetchUserBets = useCallback(async () => {
        if (!currentUser) return;

        try {
            const betsCollection = collection(firestore, 'bets');
            const userBetsQuery = query(betsCollection, where('userId', '==', currentUser.uid));
            const userBetsSnapshot = await getDocs(userBetsQuery);
            const userBetsList = userBetsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUserBets(userBetsList);
            console.log('User bets fetched:', userBetsList);
        } catch (error) {
            console.error('Error fetching user bets:', error);
        }
    }, [currentUser]);

    const fetchResults = useCallback(async () => {
        try {
            const resultsDoc = doc(firestore, 'results', 'current');
            const resultsSnapshot = await getDoc(resultsDoc);
            if (resultsSnapshot.exists()) {
                setResults(resultsSnapshot.data());
                console.log('Results fetched:', resultsSnapshot.data());
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            console.log('Fetching user bets and candidates...');
            fetchUserBets();
            fetchCandidates();
            fetchResults();
        }
    }, [currentUser, fetchUserBets, fetchCandidates, fetchResults]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const renderBet = (bet, points) => {
        const candidateNames = bet.candidateIds.map(id => candidates[id]?.region || id);
        return (
            <div key={bet.id} className="bet-item">
                <h3>{capitalizeFirstLetter(bet.category)}</h3>
                <ul>
                    {candidateNames.map(region => (
                        <li key={region}>{region}</li>
                    ))}
                </ul>
                {points !== undefined && <p>Points: {points}</p>}
                <p>Submitted on: {new Date(bet.timestamp.seconds * 1000).toLocaleString()}</p>
            </div>
        );
    };

    if (!results || userBets.length === 0) {
        console.log('Loading state:', { results, userBets });
        return <div>Loading...</div>;
    }

    const { totalPoints, pointsPerBet } = calculatePoints(userBets, results);
    console.log('Calculated points:', { totalPoints, pointsPerBet });

    return (
        <div className="my-bets-container">
            <h2>My Bets</h2>
            <div className="welcome-message">
                <h3>Welcome to Your Bets Page!</h3>
                <p>Here you can view all the bets you've placed for the Miss France competition. You'll see your predictions for the Top 15, Top 5, and the Winner, along with the points you've earned for each category.</p>
                <p>Good luck, and may the best candidate win!</p>
            </div>
            {userBets.length === 0 ? (
                <div className="no-bets-message">
                    <p>You haven't placed any bets yet. Head over to the Submit Bets page to make your predictions!</p>
                </div>
            ) : (
                <>
                    <h3>Total Points: {totalPoints}</h3>
                    <div className="bets-list">
                        {userBets.map(bet => renderBet(bet, pointsPerBet[bet.category]))}
                    </div>
                </>
            )}
        </div>
    );
};

export default MyBets;