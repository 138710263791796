import React from 'react';
import '../../styles/Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Miss France Betting App</h1>
      <p>This is a simple betting app for the Miss France pageant.</p>
      {/* Rest of your Home component content */}
    </div>
  );
};

export default Home;
